<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="26"
    viewBox="0 0 19 26"
    fill="none"
  >
    <path
      d="M9.96173 25.7846C9.96173 19.0599 1.47339 17.0105 1.47339 9.55713C1.47339 4.74373 5.2734 0.842346 9.96173 0.842346C14.6501 0.842346 18.4501 4.74373 18.4501 9.55713C18.4501 17.325 9.96173 18.5078 9.96173 25.7846Z"
      stroke="#ED166E"
      stroke-miterlimit="10"
    />
    <path
      d="M10.0383 12.5937C11.6295 12.5937 12.9194 11.2694 12.9194 9.63575C12.9194 8.00213 11.6295 6.67783 10.0383 6.67783C8.44712 6.67783 7.15723 8.00213 7.15723 9.63575C7.15723 11.2694 8.44712 12.5937 10.0383 12.5937Z"
      stroke="#ED166E"
      stroke-miterlimit="10"
    />
  </svg>
</template>
