<template>
  <form
    class="gmap-input"
    @submit.prevent="onSubmit"
  >
    <SvgLocalisationPin class="gmap-input__pin" />
    <input
      ref="input"
      type="text"
      :placeholder="`Entrez l'adresse ${mode === 'normal' ? 'de votre' : 'du'} bien`"
    >
    <HButton
      v-if="!smallerThan2xl"
      type="submit"
      class="gmap-input__button"
    >
      {{ mode === 'normal' ? 'Obtenez une offre' : 'Estimer un bien' }}
    </HButton>
    <button
      v-else
      :aria-label="mode === 'normal' ? 'Obtenez une offre' : 'Estimer un bien'"
      type="submit"
      class="gmap-input__button pink"
    >
      <SvgArrowRight />
    </button>
  </form>
</template>

<script setup lang="ts">
const router = useRouter()
const viewport = useViewport()
const { gMapLoader, gmapHelper } = useGMap()
const { startFunnel, getCurrent, clearFunnel, funnelMode } = useFunnel()

const props = withDefaults(defineProps<{
  mode?: 'normal' | 'estimate_only',
}>(), {
  mode: 'normal'
})

const input = ref()
const autocomplete = ref()
const places = ref()

const smallerThan2xl = computed(() => viewport.isLessThan('xxl'))

onMounted(async () => {
  const { Autocomplete } = await gMapLoader.importLibrary('places')

  autocomplete.value = new Autocomplete(input.value as HTMLElement, {
    componentRestrictions: {
      country: [
        'fr',
        'gp',
        'mq',
        'gf',
        're',
        'pm',
        'yt',
        'nc',
        'pf',
        'mf',
        'tf'
      ]
    },
    fields: [
      'geometry',
      'formatted_address',
      'name',
      'address_components',
      'adr_address',
      'place_id',
      'reference',
      'types',
      'url',
      'vicinity'
    ],
    types: [
      'route',
      'premise',
      'subpremise',
      'street_address',
      'street_number'
    ]
  })

  autocomplete.value?.addListener('place_changed', () => {
    places.value = autocomplete.value.getPlace()
  })
})

function onSubmit () {
  clearFunnel()
  startFunnel()
  funnelMode.value = props.mode

  const current = getCurrent('addresses')

  if (!current) {
    return
  }

  const addressObject = gmapHelper.getAddressObject(places.value.address_components)
  current.location = {
    type: 'Point',
    coordinates: [places.value.geometry.location.lng(), places.value.geometry.location.lat()]
  }
  current.raw = places.value.formatted_address
  current.street = `${addressObject.number} ${addressObject.street}`
  current.postal_code = addressObject.postal_code
  current.region = addressObject.region
  current.city = addressObject.city
  current.country = addressObject.country
  current.department_number = addressObject.department_number
  current.createInStore()

  router.push('/vendre/profil')
}

</script>

<style lang="scss" scoped>
.gmap-input {
  position: relative;

  input {
    width: 100%;
    border: 0;
    border-radius: $border-radius;
    padding: 1rem 5rem;
    font-size: 16px;
    background: $white;

    @include media-breakpoint-down(md) {
      padding: 1rem 3.5rem 1rem 3rem;
    }
  }

  &__pin {
    position: absolute;
    left: 2.19rem;
    top: 50%;
    transform: translateY(-50%);

    @include media-breakpoint-down(md) {
      left: 1.5rem;
    }
  }

  &__button {
    position: absolute !important;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &.pink {
      background: $pink;
      border: 0;
      border-radius: $button-border-radius;
      padding: 0.5rem;

      @include media-breakpoint-down(xl) {
        padding: 0.5rem 1em;
      }

      :deep(svg) {
        stroke: $white;
      }
    }
  }
}
</style>
